import { createApi } from "@reduxjs/toolkit/query/react";
import { baseAppQuery } from "./baseQuery";
import { Filter } from "types/filters";
import { SignatureData, SignatureInfo } from "types/signatures";
import { EnclosureInfo } from "types/contracts";

const ENDPOINT = "/signature";
const signatureApi: any = createApi({
  reducerPath: "signatureApi",
  baseQuery: baseAppQuery,
  keepUnusedDataFor: 0,
  tagTypes: ["signature", "appDocumentType"],
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    getSignatureListCurrent: builder.query<SignatureData, Filter>({
      query: (filter) => {
        const queryParams = new URLSearchParams();
        queryParams.set("page", filter.pageNumber.toString());
        queryParams.set("size", filter.pageSize.toString());
        return `${ENDPOINT}/fetch-all-for-current?${queryParams}`;
      },
      providesTags: ["signature"],
    }),
    getSignedSignatureByApplicationId: builder.query<
      SignatureData,
      { id: string; filter: Filter }
    >({
      query: ({ id, filter }) => {
        const queryParams = new URLSearchParams();
        if (filter) {
          queryParams.set("pageNumber", filter.pageNumber.toString());
          queryParams.set("pageSize", filter.pageSize.toString());
        }
        return `${ENDPOINT}/fetch-all-signed-for-current/${id}?${queryParams}`;
      },
    }),
    getSignatureTemplate: builder.query<SignatureData, string>({
      query: (id) => ({
        url: `${ENDPOINT}/signature-template/${id}`,
        responseHandler: (response) => response.blob().then((blob) => blob),
      }),
    }),
    getSignatureTemplateBase64: builder.query<string, string>({
      query: (id) => ({
        url: `${ENDPOINT}/signature-template/base64/${id}`,
        responseHandler: (response) => response.text(),
      }),
    }),
    getSignatureChainCmsBase64: builder.query<string, string>({
      query: (id) => ({
        url: `${ENDPOINT}/chain/cms/base64/${id}`,
        responseHandler: (response) => response.text(),
      }),
    }),
    getSignatureChainPdf: builder.query<string, string>({
      query: (id) => ({
        url: `${ENDPOINT}/chain/pdf/${id}`,
        responseHandler: (response) => response.blob().then((blob) => blob),
      }),
    }),
    getByAppDocumentType: builder.query<
      SignatureInfo,
      { applicationId: string; documentTypeId: string }
    >({
      query: ({ applicationId, documentTypeId }) =>
        `${ENDPOINT}/get-by-app-document-type?applicationId=${applicationId}&documentTypeId=${documentTypeId}`,
      providesTags: ["appDocumentType"],
    }),
    getSignaturePreviewTemplate: builder.query<
      string,
      { sourceId: string; documentTypeId: string }
    >({
      query: ({ sourceId, documentTypeId }) => ({
        url: `${ENDPOINT}/preview-template/${sourceId}/${documentTypeId}`,
        responseHandler: (response) => response.blob().then((blob) => blob),
      }),
    }),
    createAssignApprovals: builder.mutation<SignatureData, string>({
      query: (id) => ({
        url: `${ENDPOINT}/assign-approvals?applicationId=${id}`,
        method: "POST",
      }),
    }),
    createApprovalDecision: builder.mutation<SignatureData, string>({
      query: (id) => ({
        url: `${ENDPOINT}/create-approval-decision?applicationId=${id}`,
        method: "POST",
      }),
      invalidatesTags: ["appDocumentType"],
    }),
    createNoticeSignature: builder.mutation<EnclosureInfo, string>({
      query: (id) => ({
        url: `${ENDPOINT}/create-notice?noticeId=${id}`,
        method: "POST",
      }),
    }),
    createRegistrySignature: builder.mutation<EnclosureInfo, string>({
      query: (id) => ({
        url: `${ENDPOINT}/create-registry?registryId=${id}`,
        method: "POST",
      }),
    }),
    createEnclosureSignature: builder.mutation<
      EnclosureInfo,
      { id: string; type: "notice" | "registry" }
    >({
      query: ({ id, type }) => ({
        url: `${ENDPOINT}/create-${type}?${type}Id=${id}`,
        method: "POST",
      }),
    }),
    verifyCmsSignature: builder.mutation<SignatureData, string>({
      query: (data) => {
        let formData = new FormData();
        formData.append("data", data);
        return {
          url: `${ENDPOINT}/cms/verify`,
          method: "POST",
          body: formData,
        };
      },
    }),
    verifyAttachCmsSignature: builder.mutation<
    SignatureData,
    { signatureId: string; data: string; comment: string }
  >({
    query: ({ signatureId, data, comment }) => {
      let formData = undefined;
      formData = new FormData();
      formData.append("signatureId", signatureId);
      formData.append("data", data);
      formData.append("comment", comment);
      return {
        url: `${ENDPOINT}/cms/verify-attach`,
        method: "POST",
        body: formData
      };
    },
    invalidatesTags: ["signature"],
  }),
 
    verifyAttachChainCmsSignature: builder.mutation<
    SignatureData,
    { signatureId: string; data: string; comment: string }
  >({
    query: ({ signatureId, data, comment }) => {
      let formData = new FormData();
      formData.append("signatureId", signatureId);
      formData.append("data", data);
      formData.append("comment", comment);
      return {
        url: `${ENDPOINT}/chain/cms/verify-attach`,
        method: "POST",
        body: formData,
      };
    },
    invalidatesTags: ["signature"],
  }),
   // verifyAttachCmsSignature: builder.mutation<
    //   SignatureData,
    //   { signatureId: string; data: string | SignatureInfo; comment: string }
    // >({
    //   query: ({ signatureId, data, comment }) => {
    //     let formData = undefined;
    //     if (typeof data === "string") {
    //       formData = new FormData();
    //       formData.append("data", data);
    //     }
    //     return {
    //       url: `${ENDPOINT}/cms/verify-attach/${signatureId}?comment=${encodeURIComponent(comment) }`,
    //       method: "POST",
    //       body: formData || data,
    //     };
    //   },
    //   invalidatesTags: ["signature"],
    // }),
       // verifyAttachChainCmsSignature: builder.mutation<
    //   SignatureData,
    //   { signatureId: string; data: string; comment: string }
    // >({
    //   query: ({ signatureId, data, comment }) => {
    //     let formData = new FormData();
    //     formData.append("data", data);
    //     return {
    //       url: `${ENDPOINT}/chain/cms/verify-attach/${signatureId}?comment=${encodeURIComponent(comment)}`,
    //       method: "POST",
    //       body: formData,
    //     };
    //   },
    //   invalidatesTags: ["signature"],
    // }),
  }),
});

export const {
  useGetSignatureListCurrentQuery,
  useGetSignedSignatureByApplicationIdQuery,
  useGetSignatureTemplateQuery,
  useLazyGetSignatureTemplateQuery,
  useGetSignatureChainCmsBase64Query,
  useLazyGetSignatureChainCmsBase64Query,
  useGetSignatureChainPdfQuery,
  useGetByAppDocumentTypeQuery,
  useLazyGetByAppDocumentTypeQuery,
  useGetSignatureTemplateBase64Query,
  useLazyGetSignatureTemplateBase64Query,
  useGetSignaturePreviewTemplateQuery,
  useLazyGetSignaturePreviewTemplateQuery,
  useCreateAssignApprovalsMutation,
  useCreateApprovalDecisionMutation,
  useCreateNoticeSignatureMutation,
  useCreateRegistrySignatureMutation,
  useCreateEnclosureSignatureMutation,
  useVerifyCmsSignatureMutation,
  useVerifyAttachCmsSignatureMutation,
  useVerifyAttachChainCmsSignatureMutation,
} = signatureApi;
export default signatureApi;
